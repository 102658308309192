import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { IMAGES } from "../Dashboard/Content";
import CreatableSelect from "react-select/creatable";

const EditStudent = () => {
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
  };
  const RemoveFile = () => {
    setFile(null);
  };

  const [startDate, setStartDate] = useState(new Date());
  const onChange = (date) => {
    setStartDate(date);
  };

  const documentHandler = (e) => {
    const files = Array.from(e.target.files);
    setDocuments([...documents, ...files]);
  };

  const handleRemoveDocument = (index) => {
    const updatedDocuments = documents.filter((_, i) => i !== index);
    setDocuments(updatedDocuments);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h5 className="mb-0">Dados do Aluno</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xl-3 col-lg-4">
                  <label className="form-label text-primary">Photo</label>
                  <div className="avatar-upload">
                    <div className="avatar-preview">
                      <div
                        id="imagePreview"
                        style={{
                          backgroundImage: file
                            ? "url(" + URL.createObjectURL(file) + ")"
                            : "url(" + IMAGES.noimage + ")",
                        }}
                      ></div>
                    </div>
                    <div className="change-btn mt-2 mb-lg-0 mb-3">
                      <input
                        type="file"
                        className="form-control d-none"
                        onChange={fileHandler}
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                      />
                      <label
                        htmlFor="imageUpload"
                        className="dlab-upload mb-0 btn btn-primary btn-sm"
                      >
                        Escolher Imagem
                      </label>
                      <Link
                        to={"#"}
                        className="btn btn-danger light remove-img ms-2 btn-sm"
                        onClick={RemoveFile}
                      >
                        Remove
                      </Link>
                    </div>
                  </div>

                  <label className="form-label text-primary mt-3">
                    Documentos
                  </label>
                  <div className="document-upload">
                    <div className="document-preview">
                      <ul className="list-group">
                        {documents.length > 0 ? (
                          documents.map((doc, index) => (
                            <li
                              key={index}
                              className="list-group-item d-flex justify-content-between align-items-center"
                            >
                              {doc.name}
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleRemoveDocument(index)}
                              >
                                Remover
                              </button>
                            </li>
                          ))
                        ) : (
                          <li className="list-group-item text-muted">
                            Nenhum documento carregado
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="change-btn mt-2 mb-lg-0 mb-3">
                      <input
                        type="file"
                        className="form-control d-none"
                        id="documentUpload"
                        onChange={documentHandler}
                        accept=".pdf, .doc, .docx, .txt"
                        multiple
                      />
                      <label
                        htmlFor="documentUpload"
                        className="dlab-upload mb-0 btn btn-primary btn-sm"
                      >
                        Escolher Documentos
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8">
                  <div className="row">
                    <div className="col-xl-6 col-sm-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-primary"
                        >
                          Nome<span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="James"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Data de Nascimento (DD/MM/YYYY)
                          <span className="required">*</span>
                        </label>
                        <div className="d-flex">
                          <DatePicker
                            className="form-control"
                            selected={startDate}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput5"
                          className="form-label text-primary"
                        >
                          Gênero<span className="required">*</span>
                        </label>
                        <select className="form-select">
                          <option>Selecione o Gênero</option>
                          <option value="1">Masculino</option>
                          <option value="2">Feminino</option>
                        </select>
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label text-primary"
                        >
                          Endereço<span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlTextarea1"
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput2"
                          class="form-label text-primary"
                        >
                          Estado<span className="required">*</span>
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Selecione o estado</option>
                          <option value="1">Activo</option>
                          <option value="2">Inactivo</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Alergias
                        </label>
                        <CreatableSelect
                          isMulti
                          options={[
                            { value: "Amendoim", label: "Amendoim" },
                            { value: "Lactose", label: "Lactose" },
                            { value: "Glúten", label: "Glúten" },
                          ]}
                          placeholder="Selecione ou adicione uma caso nao exista na lista"
                          onChange={(selectedOptions) => {
                            console.log(selectedOptions); // selectedOptions will be an array of the selected options
                          }}
                          isClearable
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput4"
                          className="form-label text-primary"
                        >
                          Apelido<span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput4"
                          placeholder="Wally"
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput5"
                          className="form-label text-primary"
                        >
                          Responsável<span className="required">*</span>
                        </label>
                        <select className="form-select">
                          <option>Selecione o tipo de responsável</option>
                          <option value="1">Mae</option>
                          <option value="2">Pai</option>
                          <option value="3">Tutor legal</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput6"
                          className="form-label text-primary"
                        >
                          Telefone de Emergencia
                          <span className="required">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="exampleFormControlInput6"
                          placeholder="+258 84 000 0000"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput6"
                          className="form-label text-primary"
                        >
                          Classe
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput6"
                          placeholder="Berçário"
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Necessidades Especiais
                        </label>
                        <CreatableSelect
                          isMulti
                          options={[
                            {
                              value: "Atenção especial",
                              label: "Atenção especial",
                            },
                            {
                              value: "Mobilidade reduzida",
                              label: "Mobilidade reduzida",
                            },
                          ]}
                          placeholder="Selecione ou adicione uma caso nao exista na lista"
                          onChange={(selectedOptions) => {
                            console.log(selectedOptions); // selectedOptions will be an array of the selected options
                          }}
                          isClearable
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Dietas Preferidas
                        </label>
                        <CreatableSelect
                          isMulti
                          options={[
                            {
                              value: "Atenção especial",
                              label: "Atenção especial",
                            },
                            {
                              value: "Mobilidade reduzida",
                              label: "Mobilidade reduzida",
                            },
                          ]}
                          placeholder="Selecione ou adicione uma caso nao exista na lista"
                          onChange={(selectedOptions) => {
                            console.log(selectedOptions); // selectedOptions will be an array of the selected options
                          }}
                          isClearable
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0">Dados do Responsável</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-xl-6 col-sm-6">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput8"
                    className="form-label text-primary"
                  >
                    Nome<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput8"
                    placeholder="Mana"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput9"
                    className="form-label text-primary"
                  >
                    Email<span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput9"
                    placeholder="hello@example.com"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea2"
                    className="form-label text-primary"
                  >
                    Endereço<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlTextarea2"
                    placeholder="1234 Main St"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-sm-6">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput10"
                    className="form-label text-primary"
                  >
                    Apelido<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput10"
                    placeholder="Wick"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput11"
                    className="form-label text-primary"
                  >
                    Telefone<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleFormControlInput11"
                    placeholder="+258 84 000 0000"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput11"
                    className="form-label text-primary"
                  >
                    Telefone Alternativo<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleFormControlInput11"
                    placeholder="+258 84 000 0000"
                  />
                </div>
                {/* <label className="form-label text-primary">
                  Payments<span className="required">*</span>
                </label> */}
                {/* <div className="d-flex align-items-center">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label font-w500"
                      htmlFor="flexCheckDefault"
                    >
                      Cash
                    </label>
                  </div>
                  <div className="form-check ms-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault1"
                    />
                    <label
                      className="form-check-label font-w500"
                      htmlFor="flexCheckDefault1"
                    >
                      Online
                    </label>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="">
              {/* <button className="btn btn-outline-primary me-3">
                Save as Draft
              </button> */}
              <button className="btn btn-primary" type="button">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStudent;
