import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
// import styles

import {
  fetchSchool,
  updateSchool,
} from "../../../../store/slices/schoolSlice";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "react-dropzone-uploader/dist/styles.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Dropzone from "react-dropzone-uploader";
import { ToastContainer, toast } from "react-toastify";
import env from "../../../../services/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchGalleryApi } from "../../../../services/gallery-api";
import { deletePictureApi } from "../../../../services/gallery-api";
import { addPictureApi } from "../../../../services/gallery-api";
import DatePicker from "react-datepicker";

const notify = ({ type, message, title, icon }) => {
  const options = {
    position: "bottom-right", // Default position
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: icon, // Add icon if provided
    title: title, // Add title if provided
  };

  switch (type) {
    case "success":
      toast.success(message ? message : title, options);
      break;
    case "warn":
      toast.warn(message, options);
      break;
    case "error":
      toast.error(message ? message : title, options);
      break;
    case "info":
      toast.info(message, options);
      break;
    default:
      toast.info(message, options); // Default to info
  }
};

const DeleteConfirmationModal = ({ show, onHide, onConfirm, pictureName }) => (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Confirmar Exclusão</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Tem certeza que deseja excluir esta imagem{pictureName ? `: ${pictureName}` : ''}?
      Esta ação não pode ser desfeita.
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Cancelar
      </Button>
      <Button variant="danger" onClick={onConfirm}>
        Excluir
      </Button>
    </Modal.Footer>
  </Modal>
);

// Upload Modal Component with improved UI
const UploadModal = ({ show, onHide, onSubmit, multiple = true }) => {
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      // Validate file size (5MB limit)
      if (file.size > 5 * 1024 * 1024) {
        toast.error("O arquivo excede o limite de 5MB permitido");
        return;
      }
      
      setFiles(prev => [...prev, file]);
      const previewUrl = URL.createObjectURL(file);
      setPreviews(prev => [...prev, previewUrl]);
    }
  };

  const handleSubmitUpload = () => {
    if (files.length === 0) {
      toast.warning("Selecione pelo menos uma imagem para enviar");
      return;
    }
    onSubmit(files);
    setFiles([]);
    setPreviews([]);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Imagens</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropzone
          onChangeStatus={handleChangeStatus}
          inputContent={
            <div className="text-center">
              <i className="fa fa-cloud-upload fa-3x mb-2"></i>
              <p>Arraste e solte as imagens aqui ou clique para selecionar</p>
              <small className="text-muted">Limite máximo por arquivo: 5MB</small>
            </div>
          }
          accept="image/*"
          maxFiles={multiple ? 10 : 1}
          multiple={multiple}
          styles={{
            dropzone: {
              minHeight: 200,
              maxHeight: 250,
              width: "100%",
              backgroundColor: "#f8f9fa",
              border: "2px dashed #dee2e6",
              borderRadius: "8px",
              padding: "20px",
            },
            inputLabel: {
              color: "#6c757d",
            },
          }}
        />
        
        {previews.length > 0 && (
          <div className="mt-4">
            <h6>Pré-visualização:</h6>
            <div className="d-flex flex-wrap gap-2">
              {previews.map((preview, index) => (
                <div key={index} className="position-relative">
                  <img
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                  <button
                    className="btn btn-danger btn-sm position-absolute top-0 end-0"
                    onClick={() => {
                      setFiles(files.filter((_, i) => i !== index));
                      setPreviews(previews.filter((_, i) => i !== index));
                    }}
                    style={{ margin: "2px" }}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSubmitUpload}>
          Enviar Imagens
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


const EditProfile = () => {
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [gallery, setGallery] = useState([]);
  const [errors, setErrors] = useState(null);

  const dispatch = useDispatch();

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [registrationDate, setRegistrationDate] = useState(new Date());
  const [lastRegistrationDate, setLastRegistrationDate] = useState(new Date());
  const [dateError, setDateError] = useState("");

  // Update lastRegistrationDate minimum date when registrationDate changes
  useEffect(() => {
    // If lastRegistrationDate is before registrationDate, update it
    if (lastRegistrationDate < registrationDate) {
      setLastRegistrationDate(registrationDate);
      setDateError("");
    }
  }, [registrationDate]);

  // Validate last registration date
  const handleLastRegistrationChange = (date) => {
    if (date < registrationDate) {
      setDateError("A última data de inscrição não pode ser anterior à data de inscrição inicial");
      return;
    }
    
    setDateError("");
    setLastRegistrationDate(date);
  };

  // Função atualizada para lidar com múltiplos uploads
  const handleSubmitUpload = async (files) => {
    const uploadPromises = files.map(async (file) => {
      const picture = { image: file };
      return await addPictureApi(schoolId, picture, token);
    });

    try {
      const results = await Promise.all(uploadPromises);
      const successCount = results.filter(r => r.success).length;
      
      if (successCount > 0) {
        toast.success(`${successCount} ${successCount === 1 ? 'imagem adicionada' : 'imagens adicionadas'} com sucesso!`);
        fetchGallery();
      }
      
      const errorCount = results.length - successCount;
      if (errorCount > 0) {
        toast.error(`${errorCount} ${errorCount === 1 ? 'imagem falhou' : 'imagens falharam'} ao ser enviada.`);
      }
    } catch (error) {
      toast.error("Erro ao enviar imagens.");
    }
    
    setShowUploadModal(false);
  };

  // Função atualizada para confirmação de exclusão
  const handleDeletePicture = (picture) => {
    setSelectedPicture(picture);
    setShowDeleteModal(true);
  };

  // Função para confirmar e executar a exclusão
  const confirmDelete = async () => {
    try {
      await deletePictureApi(schoolId, selectedPicture.id, token);
      setGallery((prev) => prev.filter((c) => c.id !== selectedPicture.id));
      toast.success("Imagem excluída com sucesso!");
    } catch (err) {
      toast.error("Erro ao excluir imagem.");
    }
    setShowDeleteModal(false);
  };

  // Access schoolId from the Redux state
  const schoolId = useSelector((state) => state.auth.auth.school_id);
  const token = useSelector((state) => state.auth.auth.token);
  const [suggestions, setSuggestions] = useState([]);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    status: "",
    education_type: "",
    foundation_year: "",
    capacity: "",
    classrooms: "",
    email: "",
    phone: "",
    mobile: "",
    website: "",
    address: "",
    district: "",
    province: "",
    description: "",
    close_time: "",
    open_tiem: "",
  });
  const [description, setDescription] = useState(""); // Separate state for React Quill description
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enrollmentDates, setEnrollmentDates] = useState({
    start_date: formData.enrollment_start_date || '',
    end_date: formData.enrollment_end_date || ''
  });

  
  // Get school data, loading, error, and successMessage from Redux store
  const { school, loading, error, successMessage } = useSelector(
    (state) => state.school
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start loading state
    setLoadingSubmit(true);

    try {
      let updatedData = { ...formData };

      // Logo upload handling
      if (formData.logo) {
        const formDataToSend = new FormData();
        formDataToSend.append("logo", formData.logo);

        const logoResponse = await fetch(
          `${env.base_url}schools/${schoolId}/logo`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
            body: formDataToSend,
          }
        );

        const logoData = await logoResponse.json();

        if (logoResponse.ok) {
          
          toast.success("Logotipo atualizado com sucesso!");

          updatedData.full_logo_url = logoData.full_logo_url;
        } else {
          
          toast.error("Erro ao atualizar logotipo");

          setIsSubmitting(false);
          setLoadingSubmit(false);
          return;
        }
      }

      // School data update
      const response = await dispatch(updateSchool({ schoolId, updatedData }));
      
      if (response.payload) {
        notify({
          type: "success",
          message: "Informações da escola atualizadas com sucesso!",
        });
      } else {
        notify({
          type: "error",
          message: "Erro ao atualizar informações da escola",
        });
      }
    } catch (error) {
      console.error("Erro ao atualizar escola:", error);
      notify({ 
        type: "error", 
        message: "Erro ao atualizar escola. Por favor, tente novamente." 
      });
    } finally {
      setIsSubmitting(false);
      setLoadingSubmit(false);
    }
  };

  const fetchGallery = async () => {
        try {
          const data = await fetchGalleryApi(schoolId, token);
          setGallery(data);
        } catch (err) {
          setErrors(err.message);
        }
      };
      
      useEffect(() => {
        if (schoolId && token) fetchGallery();
      }, [schoolId, token]);
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      // Após o arquivo ser selecionado, exiba a pré-visualização
      const previewUrl = URL.createObjectURL(file);
      setFile(file);
      setImagePreview(previewUrl);
    }
  };

  // Função chamada quando os arquivos são enviados
  const handleSubmit = async () => {
    if (!file) {
      notify({ type: "error", message: "Selecione uma imagem antes de enviar!" });
      return; // Interrompe a execução se o arquivo não foi selecionado
    }
  
    const picture = {
      image: file, // O arquivo que foi selecionado
    };
  
    setShowModal(false);
  
    const response = await addPictureApi(schoolId, picture, token);
  
    if (response.success) {
      notify({ type: "success", message: response.message || "Imagem adicionada com sucesso!" });
    } else {
      notify({ type: "error", message: response.errors || "Erro ao adicionar imagem!" });
    }
  
    fetchGallery();
  };
  // State for form data
 

  // Fetch school info on component mount
  useEffect(() => {
    if (schoolId) {
      dispatch(fetchSchool(schoolId)); 
    }
  }, [dispatch, schoolId]);

  // Update formData when school data is fetched
  useEffect(() => {
    if (school) {
      setFormData(school); // Set form data once school data is available
      setDescription(school.description || ""); // Initialize description
    }
  }, [school]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle description changes
  const handleDescriptionChange = (value) => {
    setDescription(value);
    setFormData((prev) => ({
      ...prev,
      description: value, // Ensure the description field in formData is updated
    }));
  };

 

 /* const handleDeletePicture = async (picture) => {
      // Pergunta ao usuário se ele realmente deseja excluir a classe
      if (!window.confirm("Tem certeza de que deseja excluir a imagem?")) {
        return;
      }
      try {
        await deletePictureApi(schoolId, picture.id, token);
        setGallery((prevClasses) => prevClasses.filter((c) => c.id !== picture.id));
      } catch (err) {
        setErrors(err.message);
      }
    };*/

  //  error handling
  if (error) return <p style={{ color: "red" }}>Error: {error}</p>;

  const handleAddressChange = async (e) => {
    const query = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      address: query,
    }));

    if (query.length > 3) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=jsonv2&addressdetails=1&q=${encodeURIComponent(
            query
          )}&countrycodes=MZ&dedupe=0`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setSuggestions(data);
      } catch (error) {
        console.error("Erro ao buscar sugestões de endereço:", error);
      }
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    const {
      city,
      district,
      state_district,
      state,
      county,
      town,
      village,
      suburb,
      neighbourhood,
      municipality,
    } = suggestion.address;

    const selectedProvince = state || city || county || village || town || "";
    const selectedDistrict =
      district ||
      state_district ||
      city ||
      suburb ||
      neighbourhood ||
      municipality ||
      "";

    // Verificação se province e district contêm apenas letras
    const isProvinceValid = /^[a-zA-Z\s]+$/.test(selectedProvince);
    const isDistrictValid = /^[a-zA-Z\s]+$/.test(selectedDistrict);

    setFormData((prevState) => ({
      ...prevState,
      address: suggestion.display_name,
      latitude: suggestion.lat,
      longitude: suggestion.lon,
      // Atribuindo os valores somente se forem válidos
      province: isProvinceValid ? selectedProvince : "",

      district: isDistrictValid ? selectedDistrict : "",
    }));

    setSuggestions([]); // Limpa as sugestões após a seleção
  };

  const handleLogoChange = async  (e) => {
    const file = e.target.files[0];
    if (
      !["image/jpeg", "image/png"].includes(file.type) ||
      file.size > 2 * 1024 * 1024
    ) {
      notify({ type: "error", message: "O arquivo deve ser JPEG ou PNG e não pode exceder 2 MB.!" });
      return;
    }
    setFormData({
      ...formData,
      logo: file,
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData((prevData) => ({
        ...prevData,
        full_logo_url: reader.result,
      }));
    };
    reader.readAsDataURL(file);

    await handleUpdate({ preventDefault: () => {} });

  };

  
  // Phone number validation
  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    // Remove any non-digit characters except the + sign
    const cleanedValue = value.replace(/[^\d+]/g, '');
    
    // Ensure the value starts with +258 and limit to 12 characters total (+258 + 9 digits)
    if (cleanedValue.startsWith('+258')) {
      const phoneNumber = cleanedValue.slice(4);
      if (phoneNumber.length <= 9) {
        setFormData(prev => ({
          ...prev,
          [name]: cleanedValue
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: '+258' + cleanedValue.slice(0, 9)
      }));
    }
  };

  
  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-xl-4 col-lg-5">
          <div className="clearfix">
            <div className="card card-bx profile-card author-profile m-b30">
              <div className="card-body">
                <div className="p-5">
                  <div className="author-profile text-center">
                    <div className="author-media">
                      
                      {loading ? (
                        <Skeleton circle height={100} width={100} />
                      ) : (
                        <img style={{ width: "100px", height: "100px", objectFit: "cover" }} src={formData.full_logo_url} alt="" />
                      )}

                      <div
                        className="upload-link"
                        title=""
                        data-toggle="tooltip"
                        data-placement="right"
                        data-original-title="update"
                      >
                        <input
                          type="file"
                          className="update-flie"
                          onChange={handleLogoChange}
                        />
                        <i className="fa fa-camera"></i>
                      </div>
                    </div>
                    <div className="author-info">
                      {loading ? (
                        <Skeleton width={200} />
                      ) : (
                        <h6 className="title">{formData.name}</h6>
                      )}
                      {loading ? (
                        <Skeleton width={200} />
                      ) : (
                        <span>
                          <b>Última atualização: </b> {formData.last_update}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="info-list text-left">
                  <div className="row px-3">
                    <div className="col-sm-6 m-b30 d-flex flex-column justify-content-start">
                      <label className="form-label text-left d-block">
                        Hora de Abertura
                      </label>
                      {loading ? (
                        <Skeleton width={200} />
                      ) : (
                        <input
                          type="time"
                          name="open_time"
                          className="form-control"
                          value={formData.open_time || ""}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                    <div className="col-sm-6 m-b30 text-left">
                      <label className="form-label text-left">
                        Hora de Fecho
                      </label>
                      {loading ? (
                        <Skeleton width={200} />
                      ) : (
                        <input
                          type="time"
                          name="close_time"
                          className="form-control"
                          value={formData.close_time || ""}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card card-bx profile-card author-profile m-b30">
              <div className="card-body">
                <div className="p-3">
                  <div className="profile-interest">
                    <div className="row sp4">
                      {gallery.map((picture, index) => (
                        <div
                          className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-3 position-relative"
                          
                        >
                          <img
                            key={picture?.id}
                            src={picture?.full_image_url}
                            className="img-fluid rounded shadow-sm p-1"
                            alt="gallery"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setPhotoIndex(index);
                              setIsOpen(true);
                            }}
                          />
                          <button
                            className="btn btn-danger btn-sm position-absolute top-0 end-0"
                            style={{
                              fontSize: "0.75rem",
                              padding: "0.2rem 0.4rem",
                              lineHeight: 1,
                              top: "0.3rem",
                              right: "0.3rem",
                            }}
                            onClick={() => handleDeletePicture(picture)}
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                    </div>

                    {isOpen && (
                      <Lightbox
                        mainSrc={gallery[photoIndex]?.full_image_url}
                        nextSrc={
                          gallery[(photoIndex + 1) % gallery.length]
                            ?.full_image_url
                        }
                        prevSrc={
                          gallery[
                            (photoIndex + gallery.length - 1) % gallery.length
                          ]?.full_image_url
                        }
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                          setPhotoIndex(
                            (photoIndex + gallery.length - 1) % gallery.length
                          )
                        }
                        onMoveNextRequest={() =>
                          setPhotoIndex((photoIndex + 1) % gallery.length)
                        }
                      />
                    )}

                    <div className="mt-3">
                      <Button
                        variant="primary"
                        onClick={() => setShowUploadModal(true)}
                      >
                        Adicionar Imagem
                      </Button>
                    </div>

                     <UploadModal
                       show={showUploadModal}
                       onHide={() => setShowUploadModal(false)}
                       onSubmit={handleSubmitUpload}
                     />
               
                     <DeleteConfirmationModal
                       show={showDeleteModal}
                       onHide={() => setShowDeleteModal(false)}
                       onConfirm={confirmDelete}
                       pictureName={selectedPicture?.name}
                     />

                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-8 col-lg-9">
          <div className="card profile-card card-bx ">
            <div className="card-header">
              <h6 className="title">Atualizar dados do centro</h6>
            </div>
            <form className="profile-form" onSubmit={handleUpdate}>
              <div className="card-body">
                <h5>Detalhes básicos</h5>
                <div className="row">
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Nome</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nome"
                      value={formData.name || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Estado</label>
                    <input
                      type="text"
                      name="status"
                      className="form-control"
                      value={formData.status || ""}
                      readOnly
                    />
                  </div>
                </div>

                <h5>Informações Educacionais</h5>
                <div className="row">
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Tipo de Educação</label>
                    <input
                      type="text"
                      name="education_type"
                      className="form-control"
                      defaultValue={formData.education_type || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Idiomas</label>
                    <input
                      type="text"
                      name="languages"
                      className="form-control"
                      value={formData.languages || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Ano de Fundação:</label>
                    <input
                      type="text"
                      name="foundation_year"
                      className="form-control"
                      value={formData.foundation_year}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Capacidade:</label>
                    <input
                      type="text"
                      name="capacity"
                      className="form-control"
                      value={formData.capacity || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Salas de Aula:</label>
                    <input
                      type="text"
                      name="classrooms"
                      className="form-control"
                      value={formData.classrooms || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Inscrição</label>
                    <input
                      type="text"
                      name="enrollment_fee"
                      className="form-control"
                      value={formData.enrollment_fee || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <h5>Descrição</h5>
                <div className="row">
                  <div className="col-sm-12">
                    <ReactQuill
                      theme="snow"
                      value={description}
                      onChange={handleDescriptionChange}
                      className=""
                      id="description"
                      style={{ height: "auto" }}
                    />
                  </div>
                </div>

                <h5 className="mt-5">Informações de Contato</h5>
                <div className="row">
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      value={formData.email || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-sm-6 m-b30">
                   <label className="form-label">Telefone</label>
                   <input
                     type="tel"
                     name="phone"
                     className="form-control"
                     value={formData.phone || ""}
                     onChange={handlePhoneChange}
                     placeholder="+258"
                   />
                 </div>
         
                 <div className="col-sm-6 m-b30">
                   <label className="form-label">Telemóvel</label>
                   <input
                     type="tel"
                     name="mobile"
                     className="form-control"
                     value={formData.mobile || ""}
                     onChange={handlePhoneChange}
                     placeholder="+258"
                   />
                 </div>

                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Website</label>
                    <input
                      type="text"
                      name="website"
                      className="form-control"
                      value={formData.website || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <h5>Informações de Inscrição</h5>
                 <div className="row">
                   <div className="col-sm-12 m-b30">
                     <label className="form-label">Taxa de Inscrição</label>
                     <input
                       type="number"
                       name="enrollment_fee"
                       className="form-control"
                       value={formData.enrollment_fee || ""}
                       onChange={handleChange}
                     />
                   </div>
                   <div className="col-sm-6 m-b30">
                    <label className="form-label text-primary">
                      Data de Inscrição (DD/MM/YYYY)
                      <span className="required">*</span>
                    </label>
                    <div className="d-flex">
                      <DatePicker
                        className="form-control"
                        selected={registrationDate}
                        onChange={setRegistrationDate}
                      />
                  </div>
                   </div>
                   <div className="col-sm-6 m-b30">
                     <label className="form-label">Data Final das Inscrições
                     <span className="required">*</span>
                     </label>
                     <div className="d-flex flex-column">
                      <DatePicker
                        className={`form-control ${dateError ? 'is-invalid' : ''}`}
                        selected={lastRegistrationDate}
                        onChange={handleLastRegistrationChange}
                        minDate={registrationDate}
                      />
                      {dateError && <div className="invalid-feedback d-block">{dateError}</div>}
                    </div>
                   </div>
                 </div>

                <h5>Informações de Localização</h5>
                <div className="row">
                  <div className="col-sm-12 m-b30">
                    <label className="form-label">Endereço Completo</label>
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      value={formData.address || ""}
                      onChange={handleAddressChange}
                    />
                    {suggestions.length > 0 && (
                      <ul className="suggestions-list">
                        {suggestions.map((suggestion) => (
                          <li
                            key={suggestion.place_id}
                            onClick={() => handleSelectSuggestion(suggestion)}
                          >
                            {suggestion.display_name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Distrito</label>
                    <input
                      type="text"
                      name="district"
                      className="form-control"
                      value={formData.district || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Provincia</label>
                    <input
                      type="text"
                      name="province"
                      className="form-control"
                      value={formData.province || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer align-items-center d-flex">
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn btn-primary"
        >
          {isSubmitting ? (
            <div className="d-flex align-items-center">
              <span className="spinner-border spinner-border-sm me-2"></span>
              Atualizando...
            </div>
          ) : (
            "Atualizar"
          )}
        </button>
      </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditProfile;
